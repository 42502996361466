import { productService, catalogService } from 'services';
import { productConstants, catalogConstants, tableConstants } from '../constants';

export const productActions = {
  getItemByID,
  updateStockPromotion,
  getFavoriteProducts,
  addFavoriteProduct,
  removeFavoriteProduct,
  fetchAllProducts,
  setCountersFilters,
};

function getItemByID(id) {
  return (dispatch) => {
    dispatch(request());

    productService.getItemByID(id).then(
      (product) => {
        dispatch(success(product));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: productConstants.FETCH_REQUEST };
  }
  function success(product) {
    return { type: productConstants.FETCH_SUCCESS, product };
  }
  function failure(error) {
    return { type: productConstants.FETCH_FAILURE, error };
  }
}

function fetchProductsChunk(filter, limit, offset) {
  return catalogService.fetchOptimizedItems(filter, offset * limit, limit);
}

function fetchAllProducts(platformId, categoryIds, limit, clientId) {
  return (dispatch) => {
    const filter = {
      platform: platformId,
      clientId,
      groupItem: categoryIds,
    };
    let count = 0;

    dispatch(request({ aggrid: true }));
    return fetchProductsChunk(filter, limit, count, tableConstants.DIRECTION_REPLACE).then(
      async (items) => {
        dispatch(success({ aggrid: true }, items, tableConstants.DIRECTION_REPLACE));
      },
      () => failure(filter)
    );
  };

  function request(filter) {
    return { type: catalogConstants.FETCH_REQUEST, filter };
  }
  function success(filter, items, direction) {
    return {
      type: catalogConstants.FETCH_SUCCESS,
      items,
      filter,
      direction,
      orderBy: '',
    };
  }
  function failure(filter) {
    return { type: catalogConstants.FETCH_FAILURE, filter };
  }
}

function updateStockPromotion(data) {
  return (dispatch) => {
    dispatch(request());

    productService.updateEngagementStock(data).then(
      (promotion) => {
        dispatch(success(promotion));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: productConstants.UPDATE_REQUEST };
  }
  function success(promotion) {
    return { type: productConstants.UPDATE_SUCCESS, promotion };
  }
  function failure(error) {
    return { type: productConstants.UPDATE_FAILURE, error };
  }
}

function getFavoriteProducts() {
  return (dispatch) => {
    dispatch(request());

    productService.getFavoriteProducts().then(
      (favorites) => {
        dispatch(success(favorites));
      },
      (error) => {
        dispatch(failure(error.toString));
      }
    );
    function request() {
      return { type: productConstants.FETCH_FAVORITE_LIST_REQUEST };
    }
    function success(favorites) {
      return { type: productConstants.FETCH_FAVORITE_LIST_SUCCESS, favorites };
    }
    function failure(error) {
      return { type: productConstants.FETCH_FAVORITE_LIST_FAILURE, error };
    }
  };
}

function addFavoriteProduct(productId, callback) {
  return (dispatch) => {
    dispatch(request());

    productService.likeProduct(productId).then(
      (favorites) => {
        dispatch(success(favorites));
        callback(productId, { aggrid: true });
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: productConstants.LIKE_REQUEST };
  }
  function success(favorites) {
    return { type: productConstants.LIKE_SUCCESS, favorites };
  }
  function failure(error) {
    return { type: productConstants.LIKE_FAILURE, error };
  }
}

function setCountersFilters(counters) {
  return (dispatch) => {
    dispatch({ type: productConstants.SET_FAVORITE_COUNT, counters });
  };
}

function removeFavoriteProduct(productId, callback) {
  return (dispatch) => {
    dispatch(request());

    productService.unlikeProduct(productId).then(
      (favorites) => {
        dispatch(success(favorites));
        callback(productId, { aggrid: true });
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: productConstants.UNLIKE_REQUEST };
  }
  function success(favorites) {
    return { type: productConstants.UNLIKE_SUCCESS, favorites };
  }
  function failure(error) {
    return { type: productConstants.UNLIKE_FAILURE, error };
  }
}
