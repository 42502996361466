import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import ProductCard from 'components/ProductCard';
import { selectScreenWidth } from 'selectors/ui';
import { size } from 'components/Core/Utils/Device';
import { aggridConstants } from 'constants/Aggrid.constants';
import { analytics } from 'utils/analytics/tagmanager';
import './PromotionList.scss';
import { getValueOfAttribut } from 'helpers';
import { selectCurrentPlatform } from 'selectors/platform';
import { productActions } from 'actions';
import { selectItemsFromCatalog } from 'selectors/product';
import { promotionActions } from 'actions/Promotion';
import {
  selectPromotionData,
  selectPromotionSelectedProduct,
  selectPromotionsCurrentTab,
} from 'selectors/promotion';
import { catalogService } from 'services';
import { pageContextActions } from 'actions/PageContext';
import { selectCurrentPageContext } from 'selectors/catalog';
import localeText from './locales';
import { platformConstants } from '../../constants';
import columnDefs from './columnDefinitions';
import { ProductCardWrapper, AgListWrapper, TabWrapper } from './PromotionList.style';

const resizeGrid = (screenWidth, columnApi, gridApi) => {
  if (columnApi && gridApi) {
    columnApi.setColumnVisible('agListNameCell', true);
    columnApi.setColumnVisible('agListReferenceCell', true);
    columnApi.setColumnVisible('agListPriceCell', true);
    columnApi.setColumnVisible('favoriteCell', true);
    columnApi.setColumnVisible('agListQuantityCell', true);
    columnApi.setColumnVisible('tabletCell', false);
    columnApi.setColumnVisible('mobileCell', false);

    if (screenWidth <= size.laptopL) {
      columnApi.setColumnVisible('agListReferenceCell', false);
    }
    if (screenWidth <= size.laptop) {
      columnApi.setColumnVisible('tabletCell', true);
      columnApi.setColumnVisible('agListNameCell', false);
      columnApi.setColumnVisible('agListReferenceCell', false);
      columnApi.setColumnVisible('agListPriceCell', false);
    }
    if (screenWidth <= size.tabletR) {
      columnApi.setColumnVisible('tabletCell', false);
      columnApi.setColumnVisible('agListNameCell', true);
      columnApi.setColumnVisible('agListPriceCell', true);
    }
    if (screenWidth <= size.tablet) {
      columnApi.setColumnVisible('agListPriceCell', false);
    }
    if (screenWidth <= size.mobileL) {
      columnApi.setColumnVisible('mobileCell', true);
      columnApi.setColumnVisible('tabletCell', false);
      columnApi.setColumnVisible('agListNameCell', false);
      columnApi.setColumnVisible('agListPriceCell', false);
      columnApi.setColumnVisible('agListQuantityCell', false);
      columnApi.setColumnVisible('favoriteCell', false);
    }
    gridApi.sizeColumnsToFit();
  }
};

const PromotionList = ({
  items,
  onCellClicked,
  frameworkComponents,
  handleFilter,
  currentProduct,
  currentPlatform,
  columnApi,
  screenWidth,
  fetchProducts,
  chooseCurrentProduct,
  fetchPromotionList,
  chooseTabPromotionList,
  promotionList,
  searchValue,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [currentTab, setCurrentTab] = useState('current');
  const [rowCount, setRowCount] = useState(0);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    window.addEventListener('resize', resizeGrid(screenWidth, columnApi, gridApi));
  });

  useEffect(() => {
    fetchPromotionList(currentPlatform.id);
    setCurrentTab('current');
    chooseTabPromotionList('current');
  }, []);

  useEffect(() => {
    if (promotionList) {
      const currentTabPromotionList =
        currentTab === 'current' ? promotionList.current : promotionList.future;
      if (currentTabPromotionList) {
        const promotedItemId = currentTabPromotionList.reduce((accumulator, promotion) => {
          if (promotion.items) {
            return [...accumulator, ...promotion.items];
          }

          return accumulator;
        }, []);

        setRowData(
          items
            .filter((item) => promotedItemId.includes(String(item.id)))
            .map((item) => ({
              ...item,
              promotion: currentTabPromotionList.find(
                (promotion) => promotion.items && promotion.items.includes(String(item.id))
              ),
            }))
        );
      } else {
        setRowData([]);
      }
    }
  }, [items, currentTab, promotionList]);

  const getRowHeight = () => {
    if (window.innerWidth <= size.mobileL) {
      return aggridConstants.LIST_MOBILE_ROW_HEIGHT;
    }

    return aggridConstants.LIST_ROW_HEIGHT;
  };

  const onColumnResized = (params) => {
    params.api.resetRowHeights();
  };

  const onColumnVisible = (params) => {
    params.api.resetRowHeights();
  };

  const onFilterChanged = () => {
    if (gridApi) {
      setRowCount(gridApi.getModel().rootNode.childrenAfterFilter.length);
      if (rowCount < 1) {
        gridApi.showNoRowsOverlay();
      }
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    const updateData = () => {
      const dataSource = {
        rowCount: null,
        getRows(params) {
          const { startRow, endRow } = params;

          const parameters = {
            platform: currentPlatform.id,
            clientId: null,
            groupItem: null,
          };

          const defaultLimit = 100;

          catalogService
            .getCatalogPromotionsV4(parameters, startRow, defaultLimit)
            .then((response) => {
              let lastRow = -1;

              if (response.rowCount <= endRow) {
                lastRow = response.rowCount;
              }

              let rowCount;
              const totalRowCount = response.rowCount;
              rowCount = endRow;

              const dataProducts = response.rowData;

              if (endRow >= totalRowCount) {
                rowCount = totalRowCount;
              }

              params.successCallback(dataProducts, lastRow);
            });
        },
      };

      params.api.setDatasource(dataSource);
    };

    params.api.sizeColumnsToFit();

    window.onresize = () => {
      params.api.sizeColumnsToFit();
    };

    updateData();
  };

  const onSelectionChanged = (event) => {
    if (event.node) {
      event.node.setSelected(true);
    }
    const currentProduct = gridApi.getSelectedRows();
    chooseCurrentProduct(currentProduct[0], currentPlatform, currentTab);
  };

  const navigateToNextCell = (params) => {
    let previousCell = params.previousCellPosition;
    const suggestedNextCell = params.nextCellPosition;

    const KEY_UP = 38;
    const KEY_DOWN = 40;
    const KEY_LEFT = 37;
    const KEY_RIGHT = 39;

    switch (params.key) {
      case KEY_DOWN:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell + 1
        gridApi.forEachNode((node) => {
          if (previousCell.rowIndex + 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentProduct(node.data, currentPlatform, currentTab);
          }
        });
        return suggestedNextCell;
      case KEY_UP:
        previousCell = params.previousCellPosition;
        // set selected cell on current cell - 1
        gridApi.forEachNode((node) => {
          if (previousCell.rowIndex - 1 === node.rowIndex) {
            node.setSelected(true);
            chooseCurrentProduct(node.data, currentPlatform, currentTab);
          }
        });
        return suggestedNextCell;
      case KEY_LEFT:
        return suggestedNextCell;
      case KEY_RIGHT:
        return suggestedNextCell;
      default:
        return null;
    }
  };

  const rowHeight = getRowHeight();

  return (
    <div className="promotion-list__container">
      <div className="promotion-list__sub-container">
        <AgListWrapper className="promotion-list__wrapper">
          <ul className="promotion-list__tab-container">
            <TabWrapper
              active={currentTab === 'current'}
              onClick={() => {
                setCurrentTab('current');
                chooseTabPromotionList('current');
              }}
            >
              Promotions en cours
            </TabWrapper>
            <TabWrapper
              active={currentTab === 'future'}
              onClick={() => {
                setCurrentTab('future');
                chooseTabPromotionList('future');
              }}
            >
              Promotions à venir
            </TabWrapper>
          </ul>
          <div className="AggridContainer ag-theme-custom-list">
            <AgGridReact
              cacheBlockSize={100}
              columnDefs={columnDefs}
              currentTabPromotion={currentTab}
              defaultColDef={{ resizable: true }}
              frameworkComponents={frameworkComponents}
              getRowHeight={getRowHeight}
              loadingOverlayComponent="Spinner"
              localeText={localeText}
              navigateToNextCell={navigateToNextCell}
              onCellClicked={onCellClicked}
              onColumnResized={onColumnResized}
              onColumnVisible={onColumnVisible}
              onFilterChanged={onFilterChanged}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
              pivotMode={false}
              rowData={rowData}
              rowHeight={rowHeight}
              rowModelType="infinite"
              rowSelection="single"
              searchValue={searchValue}
              suppressContextMenu
              tooltipShowDelay={0}
            />
          </div>
        </AgListWrapper>
        {screenWidth > size.tabletR && (
          <div className="promotion-list__product-card-container">
            <div className="promotion-list__product-card-container__border">
              {currentProduct && (
                <ProductCardWrapper className="promotion-list__product-card-wrapper">
                  <ProductCard
                    product={currentProduct}
                    close={() => {
                      chooseCurrentProduct(null);
                    }}
                    handleFilter={handleFilter}
                    searchValue={searchValue}
                    source="promotionList"
                    currentList={currentTab}
                  />
                </ProductCardWrapper>
              )}
              {!currentProduct && (
                <div className="product-card__no-product">Aucun article séléctionné</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  items: selectItemsFromCatalog(state, { aggrid: true }),
  screenWidth: selectScreenWidth(state),
  currentPlatform: selectCurrentPlatform(state),
  promotionList: selectPromotionData(state),
  currentProduct: selectPromotionSelectedProduct(state),
  currentPromotionTab: selectPromotionsCurrentTab(state),
  pageContext: selectCurrentPageContext(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts: (platformId, categoryIds = []) =>
    dispatch(productActions.fetchAllProducts(platformId, categoryIds, 4200)),
  fetchPromotionList: (platformId) => dispatch(promotionActions.fetchPromotionList(platformId)),
  chooseTabPromotionList: (currentTab) => {
    const currentlist = currentTab.charAt(0).toUpperCase() + currentTab.slice(1);
    dispatch(promotionActions.chooseTabPromotionList(currentlist));
  },
  chooseCurrentProduct: (product, platform, currentTab) => {
    if (product !== null) {
      let source = '';
      if (currentTab === 'current') {
        source = 'PROMOTION_CURRENT';
      } else {
        source = 'PROMOTION_FUTURE';
      }
      analytics.productDetails(product, source);
    }
    dispatch(promotionActions.chooseCurrentProduct(product, platform));
  },
  pageContext: dispatch(pageContextActions.selectPageContext('PAGE_PROMOTION')),
});

PromotionList.propTypes = {
  frameworkComponents: PropTypes.object,
  handleFilter: PropTypes.func,
  currentProduct: PropTypes.object,
  screenWidth: PropTypes.number,
  searchValue: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionList);
