import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  numberToPrice,
  getCartsById,
  getDiscountedPrice,

} from 'helpers';
import ProductTextBloc from './ProductTextBloc';
import { getShouldDisplayOrderedBy } from 'helpers/Platform';

class ProductTotal extends React.Component {
  constructor(props) {
    super(props);
    if (this.getTotal(props)) {
      this.state = {
        total: numberToPrice(this.getTotal(props)),
      };
    } else {
      this.state = {
        total: numberToPrice(0),
      };
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const nextTotal = this.getTotal(nextProps);
    const { total } = this.state;
    const { total: nextStateTotal } = nextState;
    if (total !== nextTotal && total === nextStateTotal) {
      return true;
    }

    return false;
  }

  componentDidUpdate() {
    const { total } = this.state;
    const nextTotal = this.getTotal(this.props);
    if (total !== nextTotal) {
      this.updateState(nextTotal);
    }
  }

  getEffectiveCart(props) {
    const { product, current, currentPreorder, filter } = props;

    if (filter) {
      const { cart: cartId } = filter;
      if (cartId) {
        const carts = getCartsById(cartId);
        if (carts && carts.length) {
          return carts[0];
        }
      }
    }

    return product.is_preorder ? currentPreorder : current;
  }

  getTotal(props) {
	if (!props.product) {
      return null;
    }

    const { id: productId, pcb, price } = props.product;
    const shouldDisplayOrderedBy = getShouldDisplayOrderedBy();
    const effectiveCart = this.getEffectiveCart(props);
    const { cart_items: cartItems } = effectiveCart;

    if (!cartItems) {
      return numberToPrice(0);
    }

    const cartItem = cartItems.find(({ item_id: itemId }) => itemId === productId);

    if (!cartItem) {
      return numberToPrice(0);
    }

    const { quantity } = cartItem;
    const totalPrice =
      shouldDisplayOrderedBy === 0
        ? price * quantity
        : getDiscountedPrice(props.product, cartItem) * Number(pcb) * Number(quantity);

    return numberToPrice(totalPrice);
  }

  updateState(total) {
    this.setState({ total });
  }

  render() {
    const { ...rest } = this.props;

    const textBlocs = [
      {
        content: this.getTotal(this.props),
        isBold: true,
      },
    ];
    return <ProductTextBloc {...rest} textBlocs={textBlocs} />;
  }
}

ProductTotal.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
  current: PropTypes.object,
  currentPreorder: PropTypes.object,
  filter: PropTypes.object,
  float: PropTypes.string,
};

ProductTotal.defaultProps = {
  float: 'right',
};

const mapStateToProps = (state) => {
  const { cart } = state;
  const { current, currentPreorder } = cart;
  return {
    current,
    currentPreorder,
  };
};

export default connect(mapStateToProps)(ProductTotal);
